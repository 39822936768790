import React from "react";
import shortid from  "shortid";
const SveveSection4 = (props) => {
    return(
        <section className="section-4">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-15">
                            <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
                        </div>
                        <div className="col-lg-7"></div>
                    </div>
                </div>
            </div>
            <div className="image-block-1">
                <div className="container">
                    <div className="image-block">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-md-24 col-lg-22">
                                <div className="row">
                                    {props.imageslist?.map((object, i) => {
                                        return (
                                            <div className="col-lg-12" key={shortid.generate()}>
                                                <div className="item">
                                                    <img
                                                        src={object.image1x?.sourceUrl}
                                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                        width={object.image1x?.width}
                                                        alt={object.image1x?.altText}
                                                    />
                                                </div>
                                            </div>     
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-block-2">
                <div className="container">
                    <div className="image-block">
                        <img
                            src={props.image1x?.sourceUrl}
                            srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                            width={props.image1x?.width}
                            alt={props.image1x?.altText}
                        />          
                    </div>
                </div>
            </div>
            <div className="image-block-3">
                <div className="container">
                    <div className="image-block">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-22">
                                <div className="row">
                                    {props.imageslist2?.map((object, i) => {
                                        return (
                                            <div className="col-lg-12" key={shortid.generate()}>
                                                <div className="item">
                                                    <img
                                                        src={object.image1x?.sourceUrl}
                                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                        width={object.image1x?.width}
                                                        alt={object.image1x?.altText}
                                                    />
                                                </div>
                                            </div>     
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SveveSection4;