import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import SveveHeroSection from '../sections/caseStudies/sveve/SveveHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import SveveSection1 from '../sections/caseStudies/sveve/SveveSection1';
import SveveSection2 from '../sections/caseStudies/sveve/SveveSection2';
import SveveSection3 from '../sections/caseStudies/sveve/SveveSection3';
import SveveSection4 from '../sections/caseStudies/sveve/SveveSection4';

const Sveve = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudySvevePageACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout headerClass="white-header">
      <Seo title="Sveve" />
      <div className={"sveve-case-study page-wrapper " + bgTransition} id="sveve-casestudy-page-wrapper" ref={sectionRef}>
        <SveveHeroSection 
            preTitle={parse(mainData.sveveHeroSectionPretitle)}
            mainTitle={parse(mainData.sveveHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.sveveHeroSectionImage1x.sourceUrl,
                altText: mainData.sveveHeroSectionImage1x.altText,
                width: mainData.sveveHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.sveveHeroSectionImage2x.sourceUrl,
                altText: mainData.sveveHeroSectionImage2x.altText,
                width: mainData.sveveHeroSectionImage2x.width,
              }
            }
        />
        <ChallengesSection 
            companyInfoList={mainData.sveveChallengeSectionCompanyInfoList}
            challengesList={mainData.sveveChallengeSectionChallengesList}
        />
        <SveveSection1 
            mainTitle={parse(mainData.sveveSection1Title)}
            mainDescription={parse(mainData.sveveSection1Description)}
            imageslist={mainData.sveveSection1ImageList}
        />
        <SveveSection2 
            mainTitle={parse(mainData.sveveSection2Title)}
            mainDescription={parse(mainData.sveveSection2Description)}
            image1x={
              {
                sourceUrl: mainData.sveveSection2Image1x.sourceUrl,
                altText: mainData.sveveSection2Image1x.altText,
                width: mainData.sveveSection2Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.sveveSection2Image2x.sourceUrl,
                altText: mainData.sveveSection2Image2x.altText,
                width: mainData.sveveSection2Image2x.width,
              }
            }
            imageslist={mainData.sveveSection2ImageList}
        />
        <SveveSection3 
            mainTitle={parse(mainData.sveveSection3Title)}
            imageslist={mainData.sveveSection3ImageList}
        />
        <SveveSection4 
            mainTitle={parse(mainData.sveveSection4Title)}
            imageslist={mainData.sveveSection4ImageList}
            image1x={
              {
                sourceUrl: mainData.sveveSection4Image1x.sourceUrl,
                altText: mainData.sveveSection4Image1x.altText,
                width: mainData.sveveSection4Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.sveveSection4Image2x.sourceUrl,
                altText: mainData.sveveSection4Image2x.altText,
                width: mainData.sveveSection4Image2x.width,
              }
            }
            imageslist2={mainData.sveveSection4ImageList2}
        />
      </div>
    </Layout>
  )
}



export const SveveQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "sveve"}}) {
      nodes {
        caseStudySvevePageACF {
            sveveHeroSectionPretitle
            sveveHeroSectionTitle
            sveveHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            sveveHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            sveveChallengeSectionCompanyInfoList {
              title
              description
            }
            sveveChallengeSectionChallengesList {
              title
              description
            }
            sveveSection1Title
            sveveSection1Description
            sveveSection1ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            sveveSection2Title
            sveveSection2Description
            sveveSection2Image1x {
              altText
              sourceUrl
              width
            }
            sveveSection2Image2x {
              altText
              sourceUrl
              width
            }
            sveveSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            sveveSection3Title
            sveveSection3ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }

            sveveSection4Title
            sveveSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            sveveSection4Image1x {
              altText
              sourceUrl
              width
            }
            sveveSection4Image2x {
              altText
              sourceUrl
              width
            }
            sveveSection4ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
        }
      }
    }
}

`

export default Sveve