import * as React from "react"

const SveveHeroSection = (props) => {
    return (
        <section className="page-header-section case-study-hero-section sveve-hero-section p-b-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-13">
                        <p className="small section-description text-white">{props.preTitle}</p>
                        <h1 className="h1 large section-title extra-spacing text-white">{props.mainTitle}</h1>  
                    </div>
                    <div className="col-lg-11">
                        <div className="image-block">
                            <img
                                src={props.image1x?.sourceUrl}
                                srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                width={props.image1x?.width}
                                alt={props.image1x?.altText}
                            />
                        </div>
                        <div className="image-block-large">
                            <img
                                src="https://backend.decojent.com/wp-content/uploads/2023/01/large-hero-image@2x.webp"
                                width={100}
                                alt="hero-image"
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </section> 
    )
}

export default SveveHeroSection